// HaveBooked.tsx
import React, { useEffect } from 'react';
import { useModal } from '../lib/modal/ModalContext';
import Modal from './Modal';



const HaveBooked: React.FC = () => {
    const { hideModal, isVisible, modalContent, showModal } = useModal()
    
    
    useEffect(() => {
        const modal = <Modal key={'error'} onClose={hideModal} text='이미 예약되었습니다.' title={'확인'} ></Modal>
        showModal(modal)
    }, [])
    
    return (
    <div>
    </div>
  );
};

export default HaveBooked;
