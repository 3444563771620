import React, { createContext, useContext, useEffect, useState } from 'react';
import useRESTAPI from '../hook/RESTAPI';

interface RESTContextType {
  items: any[];
}

const RESTContext = createContext<RESTContextType | undefined>(undefined);

interface RESTProviderProps {
  endpoint: string;
  fetchParams?: Record<string, any>; 
  children: React.ReactNode;
}

export const RESTProvider: React.FC<RESTProviderProps> = ({
  endpoint,
  fetchParams = {},
  children,
}) => {
  const { items, fetchItems } = useRESTAPI(endpoint);
  const [fetchedItems, setFetchedItems] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!fetchedItems.length) { 
        console.log(fetchParams)
        const data = await fetchItems(fetchParams);
        setFetchedItems(data || []);
      }
    };
    fetchData();
  }, [endpoint, JSON.stringify(fetchParams)]); 

  return (
    <RESTContext.Provider value={{ items: fetchedItems }}>
      {children}
    </RESTContext.Provider>
  );
};

export const useDropdownWithREST = () => {
  const context = useContext(RESTContext);
  if (!context) {
    throw new Error('useDropdownWithREST must be used within a DropdownWithRESTProvider');
  }
  return context;
};
