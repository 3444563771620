import React, { useEffect, useState } from 'react';
import { useModal } from '../lib/modal/ModalContext';
import { ko } from 'date-fns/locale';
import './react-datepicker.min.css';
import DatePicker from 'react-datepicker';
import ToggleText from '../lib/modal/ToggleText';
import Modal from './Modal';
import useRESTAPI from '../lib/hook/RESTAPI';
import { useNavigate, useParams } from 'react-router-dom';



const MainPage: React.FC = () => {
  const { storeid } = useParams<{ storeid: string }>();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [bookables, setBookables] = useState<Date[]>([]);

  const [willBook, setWillBook] = useState<Date | undefined>(undefined);
  const navigate = useNavigate()


  const { addItem: addBooking } = useRESTAPI('/api/booking')
  const { fetchItems: fetchAvailables } = useRESTAPI('/api/bookable/available/' + storeid)

  const { hideModal, isVisible, modalContent, showModal } = useModal()

  useEffect(() => {

  }, [willBook]);

  useEffect(() => {

  }, [bookables]);


  useEffect(() => {
    renderInitMordal();
  }, []);

  const RenderTileContent = (date: Date) => {
    const today = new Date();
    const isPastDate = date < today && date.toDateString() !== today.toDateString();

    return (
      <div className={`font-light ${isPastDate ? 'text-gray-400' : 'text-black-400'}`}>
        {date.getDate()}
      </div>
    );
  };


  const generateTimeSlots = (date: Date, start: string, end: string) => {
    const slots: Date[] = [];


    const startTime = new Date(date);
    const endTime = new Date(date);

    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);

    startTime.setHours(startHour, startMinute, 0, 0);
    endTime.setHours(endHour, endMinute, 0, 0);


    const interval = 30 * 60 * 1000;


    let currentTime = startTime;
    while (currentTime < endTime) {
      slots.push(new Date(currentTime));
      currentTime = new Date(currentTime.getTime() + interval);
    }

    return slots;
  };

  const handleDateChange = async (date: Date | null) => {

    if (date) {
      setSelectedDate(date);
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));


      const formattedDate = utcDate.toISOString();


      const availableTimes = await fetchAvailables({ date: formattedDate });
      /* [
        {
            "id": 1806,
            "store": 0,
            "weekDay": "Tuesday",
            "type": "startTime",
            "time": "09:00:00"
        },
        {
            "id": 1807,
            "store": 0,
            "weekDay": "Tuesday",
            "type": "endTime",
            "time": "17:00:00"
        }
    ] */
      const startTimeObj = availableTimes.find((item: any) => item.type === 'startTime');
      const endTimeObj = availableTimes.find((item: any) => item.type === 'endTime');



      if (startTimeObj !== undefined) {
        const startTime = startTimeObj.time;
        const endTime = endTimeObj.time;
        const slots = generateTimeSlots(date, startTime, endTime);

        setBookables(slots);
      }
      else {
        setBookables([]);
      }
      setWillBook(undefined)

    }
  };


  const booking = async () => {

    if (!willBook)
      return;

    const { date, error } = await addBooking({
      store: 0,
      user_id: -1,
      hospital_id: storeid,
      datetime: willBook
    });


    if (error) {
      const modal = <Modal key={'error'} onClose={hideModal} text='잘못된 접근입니다.' title={'확인'} ></Modal>
      showModal(modal)
    }
    else {
      const modal = <Modal key={'complete'} onClose={hideModal} text={`예약이 완료되었습니다.
상담원이 24시간 이내에 예약 확정 연락을 드릴 예정입니다.`} title={'예약 완료'} ></Modal>
      navigate('/')
      showModal(modal)
    }

  };

  const hide = () => {
    hideModal();
  }

  const renderInitMordal = () => {

    const modal = <Modal key={'init'} onClose={hide} text={`이벤트 신청이 완료되었습니다. 
상담원이 24시간 이내에 예약 확정 연락을 드릴 예정입니다. 희망하시는 일정을 남겨주시면 먼저 일정이 배정됩니다.`} title={'예약 시작'} ></Modal>

    showModal(modal)

  }


  function renderToggleTexts(item: string, index: number) {


    const willBookString = willBook?.toLocaleTimeString('ko-KR', { hour: '2-digit', minute: '2-digit', hour12: false })


    if (item === willBookString) {
      return <div className='cursor-pointer flex justify-center items-center bg-gray-600 text-white w-[70px] rounded-full py-1 px-2  border-[1px]'>{item}</div>

    }
    return <div className='cursor-pointer flex justify-center items-center w-[70px] rounded-full py-1 px-2  border-[1px]'>{item}</div>
  }
  const generateTimeArrayString = (dates: Date[]): string[] => {
    const times: string[] = [];

    dates.forEach(date => {
      times.push(date.toLocaleTimeString('ko-KR', { hour: '2-digit', minute: '2-digit', hour12: false }));
    });

    return times;
  };


  return (
    <div className=' flex flex-col  bg-gray-100 h-full min-h-screen w-screen justify-start items-center'>
      <div className='h-6'></div>
      <div className='overflow-clip border-[1px]  rounded-xl bg-white w-[375px'>
        <div className='p-6 py-6'>
          <div className='flex flex-col justify-start items-start gap-1'>
            <div className='flex justify-start items-center gap-2'>
              <div style={{ backgroundImage: 'url(/logo.png)', backgroundSize: 'contain', backgroundPosition: 'start', backgroundRepeat: 'no-repeat', height: '30px', width: '70px' }} />
              <div className='text-lg font-normal'>진료 예약</div>
            </div>
            <div className='text-gray-500 text-xs font-sm'>예약 날짜·시간 선택</div>

          </div>
          <div className='h-10'></div>
          <DatePicker
            locale={ko}
            inline
            dateFormatCalendar='yyyy. MM'
            dateFormat="yyyy년 MM월"
            onSelect={handleDateChange}
            renderDayContents={(day, date) => {
              return RenderTileContent(date ? date : new Date());
            }}
          />
        </div>
        <hr></hr>
        <div className='px-6 py-6 '>
          {bookables.length === 0 &&
            <div className='flex-col p-6 justify-center items-center w-[350px] h-[180px]'>
              <div className='flex justify-center items-center'>
                <svg height="100px" width="100px" stroke="currentColor" fill="#848484" strokeWidth="0" viewBox="0 0 24 24" className="h-full" xmlns="http://www.w3.org/2000/svg"><g id="Clock_2"><g><path d="M12,21.933A9.933,9.933,0,1,1,21.933,12,9.944,9.944,0,0,1,12,21.933ZM12,3.067A8.933,8.933,0,1,0,20.933,12,8.943,8.943,0,0,0,12,3.067Z"></path><path d="M18,12.5H12a.429.429,0,0,1-.34-.14c-.01,0-.01-.01-.02-.02A.429.429,0,0,1,11.5,12V6a.5.5,0,0,1,1,0v5.5H18A.5.5,0,0,1,18,12.5Z"></path></g></g></svg>
              </div>
              <div className='flex justify-center items-center'>
                <div className='text-[#848484]'>진료 휴무일 입니다.</div>
              </div>
            </div>
          }

          {bookables.length > 0 &&
            <ToggleText className={'font-light text-sm grid gap-2 place-items-center grid-cols-4 rounded-lg'}
              items={generateTimeArrayString(bookables)}
              renderItem={renderToggleTexts}
              onItemClick={function (index: number, item: string): void {
                setWillBook(bookables[index])
              }} />}

        </div>
        <div className='p-6'>
        </div>
        <div onClick={booking} className={`flex justify-center  text-white px-6 py-4 items-center ${!willBook ? 'bg-[#bbbbbb]' : ' cursor-pointer bg-[#8BB0EA]'} `}> 예약하기</div>
      </div>
    </div>
  );
};

export default MainPage;

/* 
renderDayContents={(day, date) => {
  return RenderTileContent(date ? date : new Date());
}} */