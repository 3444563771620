import React, { useState, useEffect } from 'react';

interface TextareaModalProps {
  onClose: () => void;
  onSave: (inputText: string) => void; 
  defaultText?: string; 
}

const TextareaModal: React.FC<TextareaModalProps> = ({ onClose, onSave, defaultText = '' }) => {
  const [inputText, setInputText] = useState<string>(defaultText);

  useEffect(() => {
    setInputText(defaultText);
  }, [defaultText]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(event.target.value);
  };

  const handleSave = () => {
    onSave(inputText); 
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-md h-[756px] w-[1024px]">
        <h2 className="text-xl font-semibold mb-4">편집 하기</h2>
        <textarea
          value={inputText}
          onChange={handleInputChange}
          className="w-full h-[600px] p-2 border rounded mb-4 resize-none"
          rows={5}
        />
        <div className="flex justify-between">
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            저장
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400"
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default TextareaModal;
