import React, { useState } from 'react';
import { TableColumn } from './TableComponent';



interface DynamicFormProps {
    fields: TableColumn[];
    onSubmitByForm?: (event: React.FormEvent) => void;
    onSubmitByObject?: (value: any) => void;
    containerClassName?: string;
    titleClassName?: string;
    formClassName?: string;
    fieldContainerClassName?: string;
    labelClassName?: string;
    inputClassName?: string;
    buttonClassName?: string;
}

const DynamicForm: React.FC<DynamicFormProps> = ({
    fields,
    onSubmitByForm,
    onSubmitByObject,
    containerClassName = "p-6 bg-white rounded-lg shadow-lg w-[400px]",
    titleClassName = "text-2xl font-bold mb-4",
    formClassName = "",
    fieldContainerClassName = "mb-4",
    labelClassName = "block text-sm font-medium",
    inputClassName = "w-full border rounded-lg p-2",
    buttonClassName = "bg-blue-500 text-white p-2 rounded-lg"
}) => {

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        const formData = new FormData(event.target as HTMLFormElement);
        const dataObject = Object.fromEntries(formData.entries());

        const newErrors: { [key: string]: string } = {};
        fields.forEach(field => {
            if (field.required && !dataObject[field.key]) {
                newErrors[field.key] = `${field.label}을(를) 입력해 주세요.`;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setErrors({});
        
        if (onSubmitByForm) onSubmitByForm(event);
        if (onSubmitByObject) onSubmitByObject(dataObject);
    };

    return (
        <div className={containerClassName}>
            <h2 className={titleClassName}>추가하기</h2>
            <form onSubmit={onSubmit} className={formClassName}>
                {fields.map((field, index) => (
                    field.key !== "id" && (
                        <div key={index} className={fieldContainerClassName}>
                            <label className={labelClassName}>{field.label}</label>
                            <input
                                type="text"
                                name={field.key}
                                className={inputClassName}
                            />
                            {errors[field.key] && (
                                <p className="text-red-500 text-xs mt-1">{errors[field.key]}</p>
                            )}
                        </div>
                    )
                ))}
                <button type="submit" className={buttonClassName}>저장</button>
            </form>
        </div>
    );
};

export default DynamicForm;
 